<template>
    <el-dialog :visible.sync="formDialog" title="添加" :close-on-click-modal="false" @close="closeForm">
        <el-form ref="dataForm" :model="dataForm" :rules="ruleForm" label-width="100px" class="data-form">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="dataForm.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="头像" prop="user_img">
                <el-upload class="avatar-uploader" action="/" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
                    <img v-if="dataForm.user_img" :src="dataForm.user_img" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="dataForm.password" placeholder="为空默认“123456”"></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="role_id">
                <el-select v-model="dataForm.role_id" placeholder="选择角色">
                    <el-option v-for="item in checkRole" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="dataForm.sex">
                    <el-radio label="男" value="男"></el-radio>
                    <el-radio label="女" value="女"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="手机" prop="phone">
                <el-input v-model="dataForm.phone" placeholder="手机"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-radio-group v-model="dataForm.status">
                    <el-radio :label="1">正常</el-radio>
                    <el-radio :label="2">锁定</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">提交</el-button>
                <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import { getCheckRole } from '@/utils/servers/adminRole.js';
import { addUser } from '@/utils/servers/adminUser.js';
import { upload } from '@/utils/servers/upload';
export default {
    props: ['addFormDialog'],
    data() {
        return {
            formDialog: this.addFormDialog,
            dataForm: {
                username: '',
                user_img: '',
                password: '',
                role_id: '',
                sex: '男',
                phone: '',
                email: '',
                status: 1
            },
            ruleForm: {
                username: [{ required: true, trigger: 'blur' }],
                role_id: [{ required: true, trigger: 'blur' }]
            },
            checkRole: []
        };
    },
    created() {
        this.getCheckRole();
    },
    methods: {
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        upload(item) {
            const form = new FormData();
            form.append('file', item.file);
            form.append('path', 'user');
            upload(form).then(res => {
                var data = res;
                if (data.code == 1) {
                    // this.$message.success(data.msg)
                    this.dataForm.user_img = data.data.url;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckRole() {
            getCheckRole().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkRole = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        submitForm() {
            addUser(this.dataForm).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.$message.success(data.msg);
                    this.closeForm();
                    this.$emit('getUserList');
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        resetForm() {
            this.$refs.dataForm.resetFields();
        },
        closeForm() {
            this.$emit('closeForm', 'add');
        }
    }
};
</script>

<style lang="less" scoped>
.data-form {
    width: 400px;
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>
